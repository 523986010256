import { nocodeapi } from './urls';
import { get, set } from './browserStorage';
import {expirationDate, videos as vids} from '../common'

export async function getVideos(quantity = 3) {
  const videos = get('videos', 'session')
  if (videos && videos.expiration > new Date().getTime()) {
    return videos.videos
  }
  try {
    // const response = await fetch(`${nocodeapi}/yt/rVEeYhIBhEArvbUD/channelVideos?maxResults=${quantity}`)
    //   .then(response => response.json())
    // const videos = response.items.filter((v: any) => v.status.privacyStatus === 'public')
    const videos = vids
      if (videos.length) {
        set('videos', {videos, expiration: expirationDate(3)})
      }
      return videos
  } catch (error) {
    console.log('error', error)
    throw error
  }
}