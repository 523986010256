import { CardProps } from './Types'

export const HEADER_HEIGHT = '4rem'

export const navOptions = [
  // {
  //   link: '#services',
  //   text: 'Servicios'
  // },
  {
    link: '#blog',
    text: 'Blog'
  },
  {
    link: '#youtube',
    text: 'Videos'
  },
  {
    link: '#about',
    text: 'Acerca de mi'
  },
  {
    link: '#contact',
    text: 'Contacto'
  },
]

export const videos = [
  {
    contentDetails: {
      videoId: '0QKNhCqAWnY',
    },
    snippet: {
      title: 'Café con la Dra Ana Carvajal - Día Mundial de la Salud',
      thumbnails: {
        maxres: {
          url: 'https://i.ytimg.com/vi/0QKNhCqAWnY/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCIIZFu6iRhbkEA87sqRucJD0x4xA',
        }
      }
    }
  },
  {
    contentDetails: {
      videoId: 'iQvlR96EIxY',
    },
    snippet: {
      title: 'Café con la Doctora Ana Carvajal - La caminata diaria',
      thumbnails: {
        maxres: {
          url: 'https://i.ytimg.com/vi/iQvlR96EIxY/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLB2ajc6V4uUiAJTO3jMSvnYLjJB9Q',
        }
      }
    }
  },
  {
    contentDetails: {
      videoId: 'RoILd4mNMd8',
    },
    snippet: {
      title: 'Café con la Doctora Ana Carvajal - Beneficios de los ejercicios en la tercera edad',
      thumbnails: {
        maxres: {
          url: 'https://i.ytimg.com/vi/RoILd4mNMd8/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLC2NQa2QmWZteOjiQw5dXKCs2BviQ',
        }
      }
    }
  },
]

const cards: CardProps[] = [
  {
    src: 'https://images.unsplash.com/photo-1559209172-0ff8f6d49ff7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80',
    title: 'Mittens',
    content: 'This kitten is as cute as he is playful. Bring him home today!',
    extraText: '6 weeks old',
    action: {
      text: 'More info',
      onClick: () => console.log('Clicking more info!!')
    }
  },
  {
    src: 'https://images.unsplash.com/photo-1559209172-0ff8f6d49ff7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80',
    title: 'Mittens',
    content: 'This kitten is as cute as he is playful. Bring him home today!',
    extraText: '6 weeks old',
    action: {
      text: 'More info',
      onClick: () => console.log('Clicking more info!!')
    }
  },
  {
    src: 'https://images.unsplash.com/photo-1559209172-0ff8f6d49ff7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80',
    title: 'Mittens',
    content: 'This kitten is as cute as he is playful. Bring him home today!',
    extraText: '6 weeks old',
    action: {
      text: 'More info',
      onClick: () => console.log('Clicking more info!!')
    }
  },
]