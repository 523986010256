import { ContactFormReq } from '../common';
import { firestore } from './firestore';

export async function submit(req: ContactFormReq) {
  const {email, name, message} = req

  if (!email || !name || !message) {
    throw new Error('Por favor, llene los campos requeridos del formulario.')
  }

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions: any = {
    method: "post",
    headers: myHeaders,
    redirect: "follow",
    body: JSON.stringify([[new Date().toISOString(), name, email, message]])
  };

  const data = {
    date: new Date().toISOString(), 
    name, 
    email, 
    message,
  }

  // console.log('Sending data to firestore api...', data)

  try {
    const response = await firestore.write('contact-form', data)
    // console.log({response})
    if (!response.ok) {
      throw new Error(response.error as string)
    }
    return 'Mensaje enviado satisfactoriamente'
  } catch (error) {
    console.log('error@form', error)
    throw error
  }

}