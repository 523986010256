import { memo } from 'react';
import styled from 'styled-components';
import { Video } from './Video';

interface Props {
  videos?: any[]
}

export const VideoGroup: React.FC<Props> = memo(
  function VideoGroup({videos = []}) {

    if (!videos.length) {
      return null
    }
    return (
      <Container>
        {videos.map(video => (<Video key={video.contentDetails.videoId} video={video} />))}
        {/* {[videos[0], ...videos].map((video, key) => (<Video key={key} video={video} />))} */}
      </Container>
    ) 
  }
)

const Container = styled.section`
  display: flex;
  padding: 0.5rem;
  justify-content: space-around;
  width: 100%;
  gap: 1rem;
  @media only screen and (max-width: 992px) {
    -webkit-overflow-scrolling: touch;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: auto;
  }
`