import { memo, useEffect, useState } from 'react';
import { VideoGroup } from '../../components';
import { getVideos } from '../../services';

export const Videos: React.FC = memo(
  function Videos() {
    const [videos, setVideos] = useState<any[]>([]);

    useEffect(() => {
      getVideos()
      .then(res => { 
        setVideos(res)
      })
    }, [])

    return (
      <VideoGroup videos={videos} />
    ) 
  }
)
