import { memo } from "react";
import styled from "styled-components";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { Blog } from '../common';

interface Props {
  url: string;
  blog: Blog
}

export const ShareSocial: React.FC<Props> = memo(function ShareSocial({ url, blog }) {
  const {title, hashtags, summary } = blog;
  const fbHashtag = hashtags?.length ? `#${hashtags[0]}` : '';
  return (
    <Container>
      <EmailShareButton url={url} subject={title} body={emailBody}>
        <EmailIcon round size={iconSize} iconFillColor={accentColor} bgStyle={bgStyle} />
      </EmailShareButton>
      <FacebookShareButton url={url} hashtag={fbHashtag} quote={summary}>
        <FacebookIcon round size={iconSize} />
      </FacebookShareButton>
      <LinkedinShareButton url={url} title={title} summary={summary} source={source}>
        <LinkedinIcon round size={iconSize} />
      </LinkedinShareButton>
      <TwitterShareButton url={url} title={title} hashtags={hashtags} via={twitter}>
        <TwitterIcon round size={iconSize} />
      </TwitterShareButton>
      <WhatsappShareButton url={url} title={title}>
        <WhatsappIcon round size={iconSize} />
      </WhatsappShareButton>
      <TelegramShareButton url={url} title={title}>
        <TelegramIcon round size={iconSize} />
      </TelegramShareButton>
    </Container>
  );
});

const Container = styled.section`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
`;

const emailBody="Hola, te comparto este artículo que me pareció interesante:"

const twitter = "doctoracarvajal";

const source = "https://www.doctoracarvajal.com";

const accentColor = "var(--accent)";

const iconSize = 36;

const bgStyle = {
  fill: "transparent",
  stroke: accentColor,
  strokeWidth: 3,
};
