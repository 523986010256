import { get, set } from "./browserStorage";
import { contentful } from "./urls";
import { expirationDate } from "../common";

export async function getBlogPosts(limit = 1000) {
  const posts = get("posts", "session");
  if (posts && posts.expiration > new Date().getTime()) {
    return posts.posts;
  }
  try {
    const response = await fetch(
      `${contentful}/spaces/ofc2a41xeym0/environments/master/entries?access_token=l2jPON4NYZnTp3BaV5EJeMO0xZJ1E4L3axgoSUzsmBY&content_type=simpleMarkdown&limit=${limit}`
    ).then((response) => response.json());
    // console.log('Blog', response.items)
    const posts = response.items.map((item: any) => item.fields);
    set("posts", { posts, expiration: expirationDate(3) });
    return posts;
  } catch (error) {
    console.log("error", error);
    throw error;
  }
}

export async function getBlockPostById(id: string) {
  const posts = await getBlogPosts();
  return posts.find((post: any) => post.slug === id);
}
