import { collection, addDoc } from "firebase/firestore";
import { db } from '../firebase';

async function write(collectionName: string, data: any) {
  try {
    const docRef = await addDoc(collection(db, collectionName), data);
    console.log("Document written with ID: ", docRef.id);
    return {ok: true, error: null}
  } catch (e) {
    console.error("Error adding document: ", e);
    return {ok: false, error: e}
  }
}

export const firestore = {
  write,
}