const storageType = {
  local: 'local',
  session: 'session',
}

const parse = (value: any) => value ? JSON.parse(value) : value

export const get = (key: string, type: string = 'local') => {
  if (type === storageType.local) {
    return parse(localStorage.getItem(key))
  } else {
    return parse(sessionStorage.getItem(key))
  }
}

export const set = (key: string, value: any, type: string = 'local') => {
  if (type === storageType.local) {
    localStorage.setItem(key, JSON.stringify(value))
  } else {
    sessionStorage.setItem(key, JSON.stringify(value))
  }
}

export const remove = (key: string, type: string = 'local') => {
  if (type === storageType.local) {
    localStorage.removeItem(key)
  } else {
    sessionStorage.removeItem(key)
  }
}
