import { memo } from 'react';
import styled from 'styled-components';

interface Props {
  src: string
  parallax?: boolean
  title?: string
  subtitle?: string
}

export const Hero: React.FC<Props> = memo(
  function Hero({ src, parallax = true, title, subtitle }) {

    return (
      <Container src={src} parallax={parallax}>
        {title ? <H2>{title}</H2> : null}
        {subtitle ? <P>{subtitle}</P> : null}
      </Container>
    )
  }
)

const Container = styled.section<{ src: string, parallax?: boolean}>`
  background-image: url('${({src}) => src}');
  min-height: calc(100vh - 7rem);
  padding: 2rem;
  ${({ parallax }) => !parallax ? '' : `
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  `}

  @media only screen and (max-width: 992px) {
    min-height: calc(50vh - 7rem);
    background-position: 0 -15rem;
  }
`

const H2 = styled.h2`
  text-align: left;
  color: var(--foreground);
  font-size: 4rem;
  margin: 0;

  @media only screen and (max-width: 992px) {
    font-size: 2.5rem;
  }
`

const P = styled.p`
  font-size: 1.5rem;
  font-style: italic;
  color: var(--foreground);
  text-align: left;
  @media only screen and (max-width: 992px) {
    font-size: 1rem;
  }
`