import { memo } from 'react';
import styled from 'styled-components';

import { Footer } from './Footer';
import { Header } from './Header';

interface Props {
  prop?: any
}

export const Layout: React.FC<Props> = memo(
  function Layout({ prop, children }) {

    return (
      <>
        <Header />
        <Container>{children}</Container>
        <Footer />
      </>
    )
  }
)

const Container = styled.div``