import Markdown from 'markdown-to-jsx';
import { memo, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import {useParams, useNavigate} from 'react-router-dom';
import { getBlockPostById } from '../../services';
import {preProcessMd} from '../../common';
import {logEvent} from '../../firebase'

export const About: React.FC = memo(
  function About() {
    useEffect(() => {
      logEvent('page_view', {name: 'About'})
    }, [])
    const [markdown, setMarkdown] = useState('')
    const getMd = async () => fetch(await import('./About.md').then(res => res.default)).then(res => res.text())
    useEffect(() => {
      const fn = async () => {
        const md = await getMd()
        setMarkdown(preProcessMd(md))
      }
      fn()
    }, [])

    return (
      <Container>
        <Markdown>{markdown}</Markdown>
      </Container>
    )
  }
)

const Container = styled.article`
  width: 70%;
  margin: 3rem auto;
  text-align: left;

  h1, h2, h3, h4, h5, h6 {
    text-align: center;
  }

  ul, ol {
    padding: 0;
  }

  p, li {
    font-size: 1.2rem;
    text-align: justify;
    line-height: 1.5;
  }

  li {
    margin-bottom: 1rem;
  }

  .img-wrapper {
    text-align: center;
    margin: 0;
  }

  img {
    border-radius: 3px;
    max-height: 450px;
    max-width: 100%;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  }

  .author {
    display: flex;
    flex-direction: column;
  }

  .reference {
    display: flex;
    justify-content: flex-end;
    a {
      color: var(--secondary);
    }
  }

`