import { SlAnimation } from '@shoelace-style/shoelace/dist/react';
import { memo, useEffect, useRef } from 'react';
import styled from 'styled-components';

interface Props {
  box: any
  animation: string
  iterations?: number
  duration?: number
}

export const OAnimation: React.FC<Props> = memo(
  function OAnimation({ box, children, animation, iterations = 1, duration = 1000 }) {
    const ref: any = useRef(null);

    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            ref.current.play = true;
          } else {
            ref.current.play = false;
            ref.current.currentTime = 0;
          }
        }
      );

      if (box.current) {
        observer.observe(box.current);
      }
    }, [box]);
    return (
      <SlAnimation
        ref={ref}
        name={animation}
        duration={duration}
        iterations={iterations}
      >
        {children}
      </SlAnimation>
    )
  }
)

const Container = styled.section``