import { memo, useEffect } from 'react';
import styled from 'styled-components';

import { Hero } from '../../components';
import { Blog } from '../../components/Blog';
import { About } from './About';
import { ContactForm } from './ContactForm';
import { Videos } from './Videos';
import { SlDivider } from '@shoelace-style/shoelace/dist/react';
import {logEvent} from '../../firebase'

export const Home: React.FC = memo(
  function Home() {
    useEffect(() => {
      logEvent('page_view', {name: 'Home'})
    }, [])
    return (
      <Container>
        <Hero 
          src="assets/2-masks.jpeg"
          title="Doctora Ana Carvajal"
          subtitle="Especialista en enfermedades infecciosas y gerencia de la salud"
        />

        {/* <Section id="services">
          <H3>Servicios</H3>
          <P>Servicios que ofrezco</P>
          <CardGroup cards={cards} />
        </Section> */}

        <Section id="blog">
          <H3>Lo mas reciente del blog</H3>
          <Blog limit={4} />
        </Section>

        <Divider />

        {/* Youtube, Blog, Cursos */}
        <Section id="youtube">
          <H3>Lo más reciente en YouTube</H3>
          <Videos />
        </Section>

        <Divider />

        <Section id="about">
            <H3>Acerca de mi</H3>
            <About />
        </Section>

        <Divider />
        
        <Section id="contact">
          <H3>Contacto</H3>
          <ContactForm />
        </Section>

      </Container>
    )
  }
)

const Container = styled.article``

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 1rem;
`

const H3 = styled.h3`
  color: var(--primary);
  margin: 0 0 2rem;
  font-size: 2rem;

  @media only screen and (max-width: 992px) {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
`

const Divider = styled(SlDivider)`
  --color: var(--muted);
  --spacing: 1rem;
  width: 90%;
  margin: auto;
`