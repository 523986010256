export interface CardProps {
  src?: string;
  title?: string;
  content?: string;
  extraText?: string;
  animation?: string;
  alt?: string;
  action?: {
    onClick: (...args: any[]) => void;
    text: string;
  };
}

export interface ImgCardProps extends Partial<CardProps> {
  icon?: string;
  text?: string;
  orientation?: "horizontal" | "vertical";
}

export enum Orientation {
  left = "left",
  right = "right",
}

export interface ArticleProps {
  content: string;
  image?: string;
  title?: string;
  orientation?: Orientation;
}

export interface ContactFormReq {
  email: string;
  name: string;
  message: StringConstructor;
}

export interface Blog {
  author: string;
  hashtags?: string[];
  markdown: string;
  slug: string;
  summary?: string;
  title: string;
}
