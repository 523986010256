import { serialize } from '@shoelace-style/shoelace/dist/utilities/form.js';
import { memo, useRef } from 'react';
import styled from 'styled-components';

import { OAnimation } from '../../components';
import { submit } from '../../services';

export const About: React.FC = memo(
  function About() {
    const box = useRef(null);

    async function handleSubmit(event: any) {
      event.preventDefault();
      const form = document.querySelector('form');
      if (form) {
        const data = serialize(form);
        await submit(data as any)
        form.reset()
      }
    }

    return (
      <OAnimation
        box={box}
        animation={'fadeIn'}
        duration={2000}
      >
        <Container ref={box}>
            <Main>
              <P>
                Especialista en enfermedades infecciosas egresada del Hospital Universitario de Caracas, especialista en 
                Gerencia de Servicios de Salud egresada de la Universidad Católica Andrés Bello, Caracas, Venezuela.
              </P>
              <P>
                Trabajó más de 30 años en el  Hospital Universitario de Caracas (HUC) con  amplia experiencia en el 
                manejo y atención de pacientes con enfermedades infecciosas, incluyendo las personas con VIH, 
                embarazadas con diferentes infecciones y con VIH, enfermedades emergentes y re emergentes, y enfermedades tropicales. 
              </P>
              <P className="link"><a href="/about">Leer más</a></P>
            </Main>
            <img src="/assets/AnaCarvajal-short.jpeg" alt="Doctora Ana Carvajal"></img>
        </Container>
      </OAnimation>
    )
  }
)

const Container = styled.section`
  align-items: center;
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding: 4rem 1rem;
  gap: 1rem;
  h3 {
    text-align: left;
  }
  img {
    width: 100%;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  }

  @media only screen and (max-width: 992px) {
    display: block;
    padding: 0 1rem 1rem;
  }
`

const Main = styled.div`
  flex-grow: 2;
  height: 100%;
  align-self: flex-start;
  padding: 0 2rem;
  @media only screen and (max-width: 992px) {
    padding: 0;
  }
`

const P = styled.p`
  font-size: 1.2rem;
  line-height: 1.5;
  text-align: justify;

  &.link {
    text-align: right;

    a {
      color: var(--secondary);
    }
  }

  @media only screen and (max-width: 992px) {
    font-size: 1rem;
  }
`
