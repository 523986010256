import { SlIcon } from '@shoelace-style/shoelace/dist/react';
import { memo, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components';

import { HEADER_HEIGHT, navOptions } from '../common';
import { Logo } from './Logo';

export const Header: React.FC = memo(
  function Header() {
    const [isVisible, setIsVisible] = useState(false)
    const toggle = useCallback(() => {
        setIsVisible(prev => !prev)
      },
      [setIsVisible],
    )
    const handleNavClick = useCallback(({target}) => {
      document.querySelectorAll('.hash-link').forEach(e => {
        e.classList.remove('is-active')
      })
      target.classList.add('is-active')
    }, [])
    const li = navOptions.map((o, key) => {
      return (<Li key={key}><HashLink onClick={handleNavClick} smooth to={`/${o.link}`} className="hash-link">{o.text}</HashLink></Li>)
    })

    return (
      <Nav>
        <Link to="/">
          <Logo src="dac-black.png" />
        </Link>
        <CollapsibleMenu onClick={toggle}><Icon name="list" /></CollapsibleMenu>
        <Ul isVisible={isVisible}>{li}</Ul>
      </Nav>
    )
  }
)

const Nav = styled.nav`
  background-color: var(--background);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin: 0;
  max-height: ${HEADER_HEIGHT};
  box-sizing: border-box;
  position: sticky;
  top: 0;
  z-index: 99;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.3);

  a {
    color: var(--foreground);
    text-decoration: none;
    font-weight: bold;
  }

  .is-active {
    text-decoration: underline;
  }
`

const Ul = styled.ul<{isVisible: boolean}>`
  display: block;
  list-style: none;
  display: flex;
  gap: 1rem;
  padding: 0;
  margin: 0;
  @media only screen and (max-width: 992px) {
    display: ${({isVisible}) => isVisible ? 'flex' : 'none'} !important;
    position: absolute;
    right: 0.5rem;
    top: 5rem;
    background-color: var(--background);
    border: 1px solid var(--sl-panel-border-color);
    gap: 0;
    border-radius: 0.2rem;
    flex-direction: column;
  }
`

const Li = styled.li`
  @media only screen and (max-width: 992px) {
    border-bottom: solid 1px var(--sl-panel-border-color);
    padding: 1rem;

    &:nth-last-child() {
      border-bottom: none;
    }
  }
`

const CollapsibleMenu = styled.div`
  display: none;
  @media only screen and (max-width: 992px) {
    display: block;
  }
`

const Icon = styled(SlIcon)`
  font-size: 2rem;
`