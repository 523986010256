import './App.css';
import '@shoelace-style/shoelace/dist/themes/light.css';

import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { Layout } from './components';
import { Routes } from './Routes';

function App() {
  setBasePath('https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.0.0-beta.65/dist/');
  return (
    <div className="App">
      <Router>
        <Layout>
          <Routes />
        </Layout>
      </Router>
    </div>
  );
}

export default App;
