import { SlAlert, SlButton, SlIcon, SlInput, SlTextarea } from '@shoelace-style/shoelace/dist/react';
import { serialize } from '@shoelace-style/shoelace/dist/utilities/form.js';
import { memo, useRef, useState } from 'react';
import styled from 'styled-components';

import { OAnimation } from '../../components';
import { submit } from '../../services';

export const ContactForm: React.FC = memo(
  function ContactForm() {
    const box = useRef(null)
    const [success, setSuccess] = useState<boolean>()
    const [isSubmitting, setSubmitting] = useState<boolean>()

    async function handleSubmit(event: any) {
      setSuccess(undefined)
      event.preventDefault()
      const form = document.querySelector('form')
      if (form) {
        const data = serialize(form)
        try {
          setSubmitting(true)
          await submit(data as any)
          setSuccess(true)
        } catch {
          setSuccess(false)
        } finally {
          form.reset()
          setSubmitting(false)
        }
      }
    }

    return (
      <>
        {success !== undefined ?
          <Alert variant={success ? 'success' : 'danger'} open>
            <SlIcon slot="icon" name={success ? 'check2-circle' : 'exclamation-octagon'}></SlIcon>
            <strong>{
              success ?
              'Mensaje enviado exitosamente' 
              : 'Lo sentimos, no pudimos enviar tu mensaje en este momento. Intenta nuevamente en unos minutos.'
            }</strong>
          </Alert>
        : null}
        <OAnimation
            box={box}
            animation={'fadeIn'}
            duration={2000}
          >
            <Form onSubmit={handleSubmit} ref={box}>
              <SlInput name="name" placeholder="Nombre y apellido" required />
              <SlInput name="email" placeholder="Correo electronico. Ej. nombre@example.com" type="email" required />
              <SlTextarea name="message" placeholder="Escribe tu mensaje" required></SlTextarea>
              <Button type="submit" variant="primary" disabled={isSubmitting}>Enviar</Button>
            </Form>
        </OAnimation>
      </>
    )
  }
)

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;

  * > .form-control {
    text-align: left;
    color: var(--muted);
  }

  @media only screen and (max-width: 992px) {
    width: 90%;
    margin: 0 auto;
  }
`

const Button = styled(SlButton)`
  &::part(base){
    border-color: var(--primary);
    background-color: var(--primary);
  }
`

const Alert = styled(SlAlert)`
  margin-bottom: 1rem;
`