import { memo } from 'react';
import { Route, Routes as Rts } from 'react-router-dom';

import { Blog, BlogMain, Home, About } from "./pages";

export const Routes: React.FC = memo(function Routes() {
  return (
    <Rts>
      <Route path={'/blog/:id'} element={<Blog />} />
      <Route path={'/blog'} element={<BlogMain />} />
      <Route path={'/about'} element={<About />} />
      <Route path={'/'} element={<Home />} />
      <Route path={'*'} element={<Home />} />
    </Rts>
  )
})