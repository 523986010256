import { memo } from 'react';
import styled from 'styled-components';
import { ImgCard } from './ImgCard';

interface Props {
  video?: any
}

export const Video: React.FC<Props> = memo(
  function Video({video}) {
    // console.log('received video', video)

    return (
      <Container href={`https://www.youtube.com/watch?v=${video.contentDetails.videoId}`} target="_blank">
        <ImgCard 
          src={video.snippet.thumbnails.maxres.url}
          text={video.snippet.title}
          icon="youtube"
          orientation='horizontal'
        />
        <Title>{video.snippet.title}</Title>
      </Container>
    ) 
  }
)

const Container = styled.a`
  flex: 0 0 auto;
  color: var(--foreground);
  text-decoration: none;
  @media only screen and (max-width: 992px) {
    width: 95vw;
  }
`

const Title = styled.h3`
  display: none;
  width: 90%;
  font-weight: normal;
  font-size: 1rem;

  @media only screen and (max-width: 992px) {
    display: block;
  }
`