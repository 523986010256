import { memo } from 'react';
import styled from 'styled-components';

import { CardProps, ImgCardProps } from '../common';
import { Card } from './Card';
import { ImgCard } from './ImgCard';

interface Props {
  cards?: CardProps[]
  images?: ImgCardProps[]
  isCarousel?: boolean
}

export const CardGroup: React.FC<Props> = memo(
  function CardGroup({ cards, images, isCarousel = true }) {

    return (
      <Container isCarousel={isCarousel}>
        {cards && cards.map((c, key) => (<Card {...c} key={key} />))}
        {images && images.map((c, key) => (<ImgCard {...c} key={key} />))}

        {/* For testing purposes */}
        {/* {cards && [...cards, ...cards, ...cards, ...cards, ...cards, ...cards].map((c, key) => (<Card {...c} key={key} />))} */}
        {/* {images && [...images, ...images, ...images, ...images, ...images, ...images,].map((c, key) => (<ImgCard {...c} key={key} />))} */}
      </Container>
    )
  }
)

const Container = styled.section<{isCarousel: boolean}>`
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  gap: 2rem;

  ${({ isCarousel }) => isCarousel ? `
    @media only screen and (max-width: 992px) {
      -webkit-overflow-scrolling: touch;
      justify-content: flex-start;
      flex-wrap: nowrap;
      overflow-x: auto;
    }
  ` : ''};

`