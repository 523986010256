import Markdown from 'markdown-to-jsx';
import { memo, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import { getBlockPostById } from '../../services';
import {logEvent} from '../../firebase'
import { ShareSocial } from '../../components';
import { Blog as BlogType } from '../../common';

export const Blog: React.FC = memo(
  function Blog() {
    const [markdown, setMarkdown] = useState('')
    const [blog, setBlog] = useState<BlogType>({} as BlogType);
    const { id } = useParams()
    useEffect(() => {
      if (id) {
        logEvent('page_view', {name: 'Blog', page_location: window.location.href})
        getBlockPostById(id)
        .then(res => {
          setBlog(res)
          setMarkdown(preProcessMd(`${res.markdown}\n<address className="author">${res.author}</address>`))
        })
      }
    }, [])

    const preProcessMd = useCallback((text: string) => {
      const regExp = /!\[(.*?)\]\((.*?)\)/gi
      const assetsRegExp = /(\()(assets\/.+.[png|jpeg|jpg])/gi
      const content = text
        .replace(regExp, `<figure className="img-wrapper">![$1]($2)</figure>`)
        .replace(assetsRegExp, `$1${window.location.origin}/$2`)
      return content
    }, [])

    return (
      <Container>
        <ShareSocial url={window.location.href} blog={blog} />
        {/* <ShareSocial url={`https://www.doctoracarvajal.com${window.location.pathname}`} blog={blog} /> */}
        <Markdown>{markdown}</Markdown>
      </Container>
    );
  }
)

const Container = styled.article`
  width: 70%;
  margin: 3rem auto;
  text-align: left;

  h1, h2, h3, h4, h5, h6 {
    text-align: center;
  }

  ul, ol {
    padding: 0;
  }

  p, li {
    font-size: 1.2rem;
    text-align: justify;
    line-height: 1.5;
  }

  li {
    margin-bottom: 1rem;
  }

  .img-wrapper {
    text-align: center;
    margin: 0;
  }

  img {
    border-radius: 3px;
    max-height: 450px;
    max-width: 100%;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  }

  a {
    color: var(--secondary);
  }

  .author {
    display: flex;
    flex-direction: column;
  }

  .reference {
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width: 768px) {
    width: 85%;

    ul, ol {
      padding: 0 10px;
    }
  }

`