import { SlIcon } from '@shoelace-style/shoelace/dist/react';
import { memo, useRef } from 'react';
import styled from 'styled-components';

import { ImgCardProps } from '../common';
import { OAnimation } from './ObserverAnimation';

export const ImgCard: React.FC<ImgCardProps> = memo(
  function ImgCard({ src = '', text, icon, animation = 'zoomIn', orientation='vertical' }) {
    const box = useRef(null);

    return (
      <Container orientation={orientation}>
        <OAnimation
          box={box}
          animation={animation}
          duration={2000}
        >
          <Image src={src} ref={box}/>
        </OAnimation>
        {icon || text ?
          <Screen>
            {icon ? <Icon name={icon} /> : null}
            {text ? <Text>{text}</Text> : null}
          </Screen>
        : null}
      </Container>
    )
  }
)

const Container = styled.div<{orientation: 'horizontal' | 'vertical'}>`
  width: ${({orientation}) => orientation === 'vertical' ? '300px' : '350px'};
  display: block;
  position: relative;
  cursor: pointer;
  height: ${({orientation}) => orientation === 'vertical' ? '400px' : '200px'};

  &:hover > div {
    opacity: 0.9;
  }
`

const Image = styled.div<{src: string}>`
  background-image: url('${({src}) => src}');
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 0.2rem;

  @media only screen and (max-width: 992px) {
    min-width: 150px;
  }
`

const Screen = styled.div`
  background-color: var(--primary);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  opacity: 0;
  transition: opacity 1s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 992px) {
    display: none;
  }
`

const Text = styled.p`
  color: var(--background);
  font-weight: bold;
`

const Icon = styled(SlIcon)`
  color: var(--background);
  font-size: 1.5rem;
`