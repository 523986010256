import { memo } from 'react';
import styled from 'styled-components';

import { HEADER_HEIGHT } from '../common';

interface Props {
  src?: string
}

export const Logo: React.FC<Props> = memo(
  function Logo({ src = 'logo-transparent.png' }) {

    return (
      <Container>
        <Img src={`${process.env.PUBLIC_URL}/assets/${src}`} alt="Logo" />
      </Container>
    )
  }
)

const Container = styled.section`
  height: ${HEADER_HEIGHT};
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Img = styled.img`
  height: 100%;
  width: auto;
`