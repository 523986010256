import { SlButton, SlCard } from '@shoelace-style/shoelace/dist/react';
import { memo, useRef } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'usehooks-ts'
import { CardProps } from '../common';
import { OAnimation } from './ObserverAnimation';

export const Card: React.FC<CardProps> = memo(
  function Card({ src, title, content, extraText, action, alt, animation = 'zoomIn' }) {
    const box = useRef(null);
    const matches = useMediaQuery('(max-width: 992px)')

    return (
      <Container>
        <OAnimation
          box={box}
          animation={!matches ? animation : ''}
          duration={2000}
        >
          <SlCard ref={box} className="card-overview box">
            {src ?
              <img
                slot="image"
                src={src}
                alt={(alt || title) || 'Card image'}
              />
              : null}

            {title ? <Title>{title}</Title> : null}
            <Content>{content}</Content>
            {extraText ? <Text>{extraText}</Text> : null}
            {action ?
              <Footer slot="footer">
                <Button variant="primary" pill onClick={action.onClick}>{action.text}</Button>
              </Footer>
              : null}
          </SlCard>
        </OAnimation>
      </Container>
    )
  }
)

const Container = styled.section`
  max-width: 300px;
  transition: transform 0.3s ease-in-out;

  .card-overview {
    width: 100%;
    height: 100%;

    ::part(base) {
      height: 100%;
      display: grid;
      grid-template-rows: 55% 25% auto;
    }

    ::part(body) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (min-width: 993px) {
    &:hover {
      transform: translate(0, -1rem);
    }
  }
  @media (max-width: 992px) {
    max-width: 70%;
    max-height: 400px;
    flex: 0 0 auto;

    .card-overview {
      ::part(base) {
        grid-template-rows: 35% 40% auto;
        overflow: hidden;
      }
      ::part(body) {
        padding: 0.7rem 0;
      }
    }
  }
`;

const Title = styled.h5`
  margin: 0.5rem;
  font-size: 1rem;
  text-align: center;
  margin: 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 4.5rem;
`

const Content = styled.p`
  margin: 0.5rem;
  line-height: 1.5;

  @media only screen and (max-width: 992px) {
    line-height: 1;
    font-size: 0.9rem;
  }
`

const Text = styled.small`
  color: var(--sl-color-neutral-500);
`

const Footer = styled.div`
  display: flex; 
  justify-content: center;
  align-items: center;
`

const Button = styled(SlButton)`
  width: 100%;

  &::part(base){
    border-color: var(--primary);
    background-color: var(--primary);
  }
`

