import { memo, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CardGroup } from '.';
import { getBlogPosts } from '../services';
import styled from 'styled-components';

interface Props {
  limit?: number;
  isPage?: boolean;
  isCarousel?: boolean;
}

export const Blog: React.FC<Props> = memo(function Blog({ limit = 1000, isPage = false, isCarousel }) {
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    getBlogPosts(limit).then((res) => {
      // console.log('Blog Res >>>', res)
      setPosts(res);
    });
  }, []);

  const cards = posts.map((post: any, i: number) => {
    return {
      // title: post.title,
      // TODO: Fix alignment
      // src: i % 2 === 0 ? 'assets/doctor-laptop.jpeg' : 'assets/laptop-book.jpeg',
      src: "assets/laptop-book.jpeg",
      content: post.title,
      action: {
        onClick: () => navigate(`/blog/${post.slug}`),
        text: "Leer más",
      },
    };
  });

  return (
    <>
      <CardGroup cards={cards} isCarousel={isCarousel} />
      {!isPage && (
        <LinkWrapper>
          <Link to="/blog">ver más...</Link>
        </LinkWrapper>
      )}
    </>
  );
});

const LinkWrapper = styled.div`
  width: 100%;
  text-align: end;
  margin: 1rem 5rem 1rem 0;
  font-size: 1.2rem;
  line-height: 1.5;
  color: var(--secondary);
`;