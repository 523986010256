import { SlIcon } from '@shoelace-style/shoelace/dist/react';
import { memo } from 'react';
import styled from 'styled-components';
import { navOptions } from '../common';

import { Logo } from './Logo';

export const Footer: React.FC = memo(
  function Footer() {
    const year = new Date().getFullYear()
    const socialMedia = [
      {
        name: 'facebook',
        link: '',
      },
      {
        name: 'twitter',
        link: 'https://twitter.com/doctoracarvajal',
      },
      {
        name: 'instagram',
        link: 'https://www.instagram.com/doctoracarvajal/',
      },
      {
        name: 'youtube',
        link: 'https://www.youtube.com/channel/UCe9KR8uOnis1SwT3DbFBPcQ',
      },
    ]
    return (
      <Container>
        <Logo src="logo-transparent-black.png" />
        {/* <SlDivider vertical /> */}
        <Wrapper>{navOptions.map((l, key) => (<Link key={key} href={l.link}>{l.text}</Link>))}</Wrapper>
        <Wrapper>
          {socialMedia.map((sm, key) => (<Anchor key={key} href={sm.link} target="_blank"><Icon name={sm.name} /></Anchor>))}
        </Wrapper>
        <Copy>&copy; {year} Doctora Ana Carvajal. All rights reserved.</Copy>
        {/* <Main>
          <Section>
            <Wrapper>
              {socialMedia.map((sm, key) => (<Anchor key={key} href={sm.link} target="_blank"><Icon name={sm.name} /></Anchor>))}
            </Wrapper>
          </Section>
          <Section>
            <Wrapper>{links.map((l, key) => (<Link key={key} href={l.link}>{l.text}</Link>))}</Wrapper>
            <Copy>&copy; {year} Doctora Ana Carvajal. All rights reserved.</Copy>
          </Section>
        </Main> */}
      </Container>
    )
  }
)

const Container = styled.section`
  background-color: var(--background-2);
  height: 50vh;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
`

const Main = styled.section`
  align-items: center;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
`

const Copy = styled.small`
  color: var(--foreground);
`

const Wrapper = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  width: 100%;
`

const Link = styled.a`
  text-decoration: none;
  color: var(--foreground);
  font-size: 0.9rem;
`

const Section = styled.div`
  text-align: left;
`

const Icon = styled(SlIcon)`
  color: var(--foreground);
  font-size: 1.2rem;

  &:hover {
    background-color: var(--foreground)
    color: var(--background-2)
  }
`

const Anchor = styled.a`
  text-decoration: none;
`