import { memo } from 'react';
import { Blog } from '../../components/Blog';
import styled from 'styled-components';

export const BlogMain: React.FC = memo(
  function BlogMain() {
    return (
      <Container>
        <H1>Blog</H1>
        <Blog isPage isCarousel={false} />
      </Container>
    );
  }
)

const Container = styled.article`
  width: 80%;
  margin: 2rem auto;
`;

const H1 = styled.h1`
  color: var(--primary);
  margin: 0 0 2rem;
  font-size: 2rem;

  @media only screen and (max-width: 992px) {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
`;